/*svg {
    border: 1px solid red;
}*/

// from App.scss
$primary: hsl(195, 50%, 52%);

g.pedigree {
  // g.node { border: 1px solid green; }

  a {
    text-decoration: none;

    :hover {
      fill: $primary;
      text-decoration: none;
    }
  }

  text {
    text-rendering: optimizeLegibility;
  }

  // text.name {}

  text.extra {
    font-size: 70%;
    fill: hsl(0, 0%, 75%);

    &.titles {
      fill: hsl(0, 0%, 60%);
    }
  }

  path.under,
  path.link {
    fill: none;
    shape-rendering: geometricPrecision;
    stroke-width: 1;
  }

  path.sex-M {
    stroke: hsl(220, 50%, 50%);
  }
  path.sex-F {
    stroke: hsl(0, 50%, 55%);
  }
  path.sex-unknown {
    stroke: hsl(0, 0%, 80%);
  }
  path.sex- {
    stroke: hsl(120, 100%, 50%);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Manuale:ital,wght@0,400;0,700;1,400;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

$font-family-sans: 'Open Sans';
// bootstrap defines 'font-family-sans-serif', but it's only used for then
// defining base... but just in case (maybe used for' display-1', etc.?)
$font-family-sans-serif: $font-family-sans;
$font-size-base: 1.1rem;

$headings-font-family: 'Manuale';

// $btn-font-family: $font-family-sans;

$primary: hsl(195, 50%, 52%);
$secondary: hsl(195, 30%, 75%);
$success: hsl(120, 30%, 50%);
$info: hsl(195, 15%, 80%);
$warning: hsl(50, 50%, 50%);
$danger: hsl(10, 50%, 50%);
$light: hsl(195, 5%, 90%);
$dark: hsl(195, 30%, 15%);

// $link-color: hsl(220, 60%, 50%);

@import 'bootstrap/scss/bootstrap.scss';

/* make printing better? */
/*@media (min-width: 992px) {*/
@media print {
  /* links don't need color, nor underline, when printing! */
  #root .App a {
    color: inherit;
    text-decoration: none;
  }

  /*
    I would really love to hide non-active nav pills.... but can't easily
    select *parent* .nav-item elements when their child is not active.
  */
  #root .App .nav-pills .nav-item .nav-link:not(.active) {
    color: #6c757d; /* text-gray */
  }

  #root .App .nav-pills .nav-item .nav-link.active {
    font-weight: bold;
    border: 1px solid;
  }
}

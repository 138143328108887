.tradingcard {
  margin: 1em 0;
  border-top: 3px solid #a0a0a0;
  padding: 1em 0;

  // h2 {}

  h4 {
    margin-top: 0.5em;
    border-top: 1px solid #c0c0c0;
    padding-top: 0.5em;
    font-style: italic;
  }

  h5 {
    color: #800000;
  }

  .depth-0 {
    font-weight: bold;
    font-style: italic;
  }

  .depth-1 {
    margin-left: 2em;
  }

  .depth-2 {
    margin-left: 4em;
  }
}
